/* eslint-disable max-lines */
import cx from 'clsx';
import AddCopy from 'icons/add-copy.svg';
import AiStar from 'icons/ai-star.svg';
import Archive from 'icons/archive.svg';
import Arrow from 'icons/arrow.svg';
import Attachment from 'icons/attachment.svg';
import Bell from 'icons/bell.svg';
import BoldLogoShort from 'icons/bold-logo-short.svg';
import BoldLogo from 'icons/bold-logo.svg';
import BoldText from 'icons/bold-text.svg';
import bold from 'icons/bold.svg';
import Book from 'icons/book.svg';
import Briefcase from 'icons/briefcase.svg';
import BubbleStar from 'icons/bubble-star.svg';
import Building from 'icons/building.svg';
import Calendar from 'icons/calendar.svg';
import Chat from 'icons/chat.svg';
import CheckCircle from 'icons/check-circle.svg';
import Check from 'icons/check.svg';
import CheckboxChecked from 'icons/checkbox-checked.svg';
import Cogwheel from 'icons/cogwheel.svg';
import ColorPicker from 'icons/color-picker.svg';
import Computer from 'icons/computer.svg';
import Contract from 'icons/contract.svg';
import Copy from 'icons/copy.svg';
import Copyright from 'icons/copyright.svg';
import CrossCircle from 'icons/cross-circle.svg';
import Cross from 'icons/cross.svg';
import Dashboard from 'icons/dashboard.svg';
import Dismiss from 'icons/dismiss.svg';
import DocumentDelete from 'icons/document-delete.svg';
import DocumentPen from 'icons/document-pen.svg';
import Document from 'icons/document.svg';
import Documents from 'icons/documents.svg';
import Download from 'icons/download.svg';
import Drawer from 'icons/drawer.svg';
import Edit from 'icons/edit.svg';
import EmptyStateBadge from 'icons/empty-state-badge.svg';
import EmptyStateBox from 'icons/empty-state-box.svg';
import EmptyStateBrand from 'icons/empty-state-brand.svg';
import EmptyStateCse from 'icons/empty-state-cse.svg';
import EmptyStateDocument from 'icons/empty-state-document.svg';
import EmptyStateDomainName from 'icons/empty-state-domain-name.svg';
import EmptyStateEmployee from 'icons/empty-state-employee.svg';
import EmptyStateExceptionalOperation from 'icons/empty-state-exceptional-operation.svg';
import EmptyStateFilledHourglass from 'icons/empty-state-filled-hourglass.svg';
import EmptyStateHourglass from 'icons/empty-state-hourglass.svg';
import EmptyStateImportDocument from 'icons/empty-state-import-document.svg';
import EmptyStateInformationCircle from 'icons/empty-state-information-circle.svg';
import EmptyStateInvoices from 'icons/empty-state-invoices.svg';
import EmptyStateLightyear from 'icons/empty-state-lightyear.svg';
import EmptyStateLock from 'icons/empty-state-lock.svg';
import EmptyStateNews from 'icons/empty-state-news.svg';
import EmptyStateOperation from 'icons/empty-state-operation.svg';
import EmptyStatePatent from 'icons/empty-state-patent.svg';
import EmptyStatePerks from 'icons/empty-state-perks.svg';
import EmptyStatePerson from 'icons/empty-state-person.svg';
import EmptyStatePointer from 'icons/empty-state-pointer.svg';
import EmptyStateSubscription from 'icons/empty-state-subscription.svg';
import EmptyStateTrademark from 'icons/empty-state-trademark.svg';
import EmptyStateUserSearch from 'icons/empty-state-user-search.svg';
import EmptyStateVesting from 'icons/empty-state-vesting.svg';
import Euro from 'icons/euro.svg';
import Excel from 'icons/excel.svg';
import Exchange from 'icons/exchange.svg';
import ExclamationMarkCircle from 'icons/exclamation-mark-circle.svg';
import ExternalLink from 'icons/external-link.svg';
import Eye from 'icons/eye.svg';
import Female from 'icons/female.svg';
import Filter from 'icons/filter.svg';
import Flash from 'icons/flash.svg';
import FloppyDisk from 'icons/floppy-disk.svg';
import Folder from 'icons/folder.svg';
import FormDelete from 'icons/form-delete.svg';
import FormValidation from 'icons/form-validation.svg';
import Gift from 'icons/gift.svg';
import Globe from 'icons/globe.svg';
import Gmail from 'icons/gmail.svg';
import Graph from 'icons/graph.svg';
import GreaterThan from 'icons/greater-than.svg';
import Group from 'icons/group.svg';
import H1 from 'icons/h1.svg';
import H2 from 'icons/h2.svg';
import Handshake from 'icons/handshake.svg';
import Heart from 'icons/heart.svg';
import Hexadecagon from 'icons/hexadecagon.svg';
import Hire from 'icons/hire.svg';
import HorizontalArrow from 'icons/horizontal-arrow.svg';
import Hourglass from 'icons/hourglass.svg';
import Id from 'icons/id.svg';
import Image from 'icons/image.svg';
import IncreaseText from 'icons/increase-text.svg';
import Indent from 'icons/indent.svg';
import Info from 'icons/info.svg';
import InformationCircle from 'icons/information-circle.svg';
import Information from 'icons/information.svg';
import Italic from 'icons/italic.svg';
import Key from 'icons/key.svg';
import LegalNotice from 'icons/legal-notice.svg';
import Letter from 'icons/letter.svg';
import Lightbulb from 'icons/lightbulb.svg';
import Lightyear from 'icons/lightyear.svg';
import Link from 'icons/link.svg';
import LinkedIn from 'icons/linkedin.svg';
import Lock from 'icons/lock.svg';
import MagnifyingGlass from 'icons/magnifying-glass.svg';
import Male from 'icons/male.svg';
import Medical from 'icons/medical.svg';
import Menu from 'icons/menu.svg';
import Minus from 'icons/minus.svg';
import More from 'icons/more.svg';
import noResults from 'icons/no-results.svg';
import OptionActive from 'icons/option-active.svg';
import OrderedList from 'icons/ordered-list.svg';
import Outdent from 'icons/outdent.svg';
import Paint from 'icons/paint.svg';
import PaperAirplane from 'icons/paper-airplane.svg';
import Paragraph from 'icons/paragraph.svg';
import Pause from 'icons/pause.svg';
import Pdf from 'icons/pdf.svg';
import Pending from 'icons/pending.svg';
import People from 'icons/people.svg';
import Phone from 'icons/phone.svg';
import PhotoCamera from 'icons/photo-camera.svg';
import Pin from 'icons/pin.svg';
import PlayBlue from 'icons/play-blue.svg';
import Play from 'icons/play.svg';
import Plus from 'icons/plus.svg';
import Powerpoint from 'icons/powerpoint.svg';
import QuestionMarkFilled from 'icons/question-mark-filled.svg';
import QuestionMark from 'icons/question-mark.svg';
import QuestionMessage from 'icons/question-message.svg';
import Reload from 'icons/reload.svg';
import Renew from 'icons/renew.svg';
import ReplyAll from 'icons/reply-all.svg';
import Reply from 'icons/reply.svg';
import Request from 'icons/request.svg';
import RipDocument from 'icons/rip-document.svg';
import Risk from 'icons/risk.svg';
import Security from 'icons/security.svg';
import Shield from 'icons/shield.svg';
import Signature from 'icons/signature.svg';
import Skull from 'icons/skull.svg';
import Slack from 'icons/slack.svg';
import Smiley from 'icons/smiley.svg';
import Smoke from 'icons/smoke.svg';
import SortDesc from 'icons/sort-desc.svg';
import Sort from 'icons/sort.svg';
import Spark from 'icons/spark.svg';
import Spinner from 'icons/spinner.svg';
import StatsActions from 'icons/stats-actions.svg';
import StatsCrm from 'icons/stats-crm.svg';
import StatsSocial from 'icons/stats-social.svg';
import Swearing from 'icons/swearing.svg';
import Tag from 'icons/tag.svg';
import Target from 'icons/target.svg';
import ThumbUp from 'icons/thumb-up.svg';
import Time from 'icons/time.svg';
import Trademark from 'icons/trademark.svg';
import Trash from 'icons/trash.svg';
import Unarchive from 'icons/unarchive.svg';
import Underline from 'icons/underline.svg';
import UnorderedList from 'icons/unordered-list.svg';
import Upload from 'icons/upload.svg';
import User from 'icons/user.svg';
import Wallet from 'icons/wallet.svg';
import Warning from 'icons/warning.svg';
import Word from 'icons/word.svg';
import { LegacyRef, forwardRef } from 'react';
import { Sizes, Icons } from 'v2.api/src/common-generic/types/icons';
export const icons = {
  'ai-star': AiStar,
  bell: Bell,
  trademark: Trademark,
  'greater-than': GreaterThan,
  'floppy-disk': FloppyDisk,
  id: Id,
  'information-circle': InformationCircle,
  plus: Plus,
  download: Download,
  drawer: Drawer,
  'magnifying-glass': MagnifyingGlass,
  'bold-logo': BoldLogo,
  'bold-logo-short': BoldLogoShort,
  'exclamation-mark-circle': ExclamationMarkCircle,
  'horizontal-arrow': HorizontalArrow,
  slack: Slack,
  check: Check,
  exchange: Exchange,
  computer: Computer,
  lightbulb: Lightbulb,
  indent: Indent,
  outdent: Outdent,
  cogwheel: Cogwheel,
  documents: Documents,
  information: Information,
  skull: Skull,
  edit: Edit,
  arrow: Arrow,
  pending: Pending,
  user: User,
  calendar: Calendar,
  copyright: Copyright,
  'external-link': ExternalLink,
  'bubble-star': BubbleStar,
  warning: Warning,
  spinner: Spinner,
  document: Document,
  cross: Cross,
  euro: Euro,
  chat: Chat,
  heart: Heart,
  'paper-airplane': PaperAirplane,
  reload: Reload,
  upload: Upload,
  trash: Trash,
  filter: Filter,
  building: Building,
  hourglass: Hourglass,
  archive: Archive,
  h1: H1,
  h2: H2,
  unarchive: Unarchive,
  'increase-text': IncreaseText,
  'bold-text': BoldText,
  underline: Underline,
  link: Link,
  gmail: Gmail,
  phone: Phone,
  letter: Letter,
  lock: Lock,
  linkedin: LinkedIn,
  minus: Minus,
  'question-mark': QuestionMark,
  'question-mark-filled': QuestionMarkFilled,
  'question-message': QuestionMessage,
  medical: Medical,
  security: Security,
  smoke: Smoke,
  risk: Risk,
  contract: Contract,
  swearing: Swearing,
  time: Time,
  hexadecagon: Hexadecagon,
  'document-pen': DocumentPen,
  book: Book,
  sort: Sort,
  'sort-desc': SortDesc,
  handshake: Handshake,
  people: People,
  key: Key,
  briefcase: Briefcase,
  signature: Signature,
  attachment: Attachment,
  pause: Pause,
  'thumb-up': ThumbUp,
  'no-results': noResults,
  'empty-state-document': EmptyStateDocument,
  'empty-state-badge': EmptyStateBadge,
  'empty-state-box': EmptyStateBox,
  'empty-state-hourglass': EmptyStateHourglass,
  'empty-state-lightyear': EmptyStateLightyear,
  'empty-state-lock': EmptyStateLock,
  'empty-state-filled-hourglass': EmptyStateFilledHourglass,
  'empty-state-operation': EmptyStateOperation,
  'empty-state-person': EmptyStatePerson,
  'empty-state-trademark': EmptyStateTrademark,
  'empty-state-employee': EmptyStateEmployee,
  'empty-state-invoices': EmptyStateInvoices,
  'empty-state-information-circle': EmptyStateInformationCircle,
  'empty-state-news': EmptyStateNews,
  'empty-state-import-document': EmptyStateImportDocument,
  'empty-state-exceptional-operation': EmptyStateExceptionalOperation,
  'empty-state-subscription': EmptyStateSubscription,
  'empty-state-perks': EmptyStatePerks,
  'empty-state-patent': EmptyStatePatent,
  'empty-state-domain-name': EmptyStateDomainName,
  'empty-state-vesting': EmptyStateVesting,
  'empty-state-user-search': EmptyStateUserSearch,
  'empty-state-pointer': EmptyStatePointer,
  'empty-state-cse': EmptyStateCse,
  'empty-state-brand': EmptyStateBrand,
  eye: Eye,
  lightyear: Lightyear,
  bold,
  italic: Italic,
  'unordered-list': UnorderedList,
  'ordered-list': OrderedList,
  'cross-circle': CrossCircle,
  'check-circle': CheckCircle,
  'rip-document': RipDocument,
  tag: Tag,
  dismiss: Dismiss,
  smiley: Smiley,
  gift: Gift,
  hire: Hire,
  renew: Renew,
  shield: Shield,
  flash: Flash,
  copy: Copy,
  more: More,
  wallet: Wallet,
  pdf: Pdf,
  word: Word,
  image: Image,
  excel: Excel,
  powerpoint: Powerpoint,
  globe: Globe,
  'option-active': OptionActive,
  'checkbox-checked': CheckboxChecked,
  'color-picker': ColorPicker,
  play: Play,
  'play-blue': PlayBlue,
  reply: Reply,
  'reply-all': ReplyAll,
  group: Group,
  paragraph: Paragraph,
  paint: Paint,
  request: Request,
  info: Info,
  graph: Graph,
  'form-delete': FormDelete,
  'form-validation': FormValidation,
  'document-delete': DocumentDelete,
  'stats-crm': StatsCrm,
  'stats-actions': StatsActions,
  'stats-social': StatsSocial,
  'add-copy': AddCopy,
  pin: Pin,
  'legal-notice': LegalNotice,
  target: Target,
  female: Female,
  male: Male,
  dashboard: Dashboard,
  spark: Spark,
  folder: Folder,
  menu: Menu,
  'photo-camera': PhotoCamera,
};

interface Props {
  type: Icons;
  className?: string;
  size?: Sizes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  onClick?: (args: unknown) => void;
}

const sizes = {
  xs: 'w-2.5 h-2.5',
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  lg: 'w-6 h-6',
  xl: 'w-10 h-10',
  custom: '',
};

const Icon = forwardRef(function Icon(
  { type, className, size, onClick }: Props,
  forwardedRef: LegacyRef<HTMLButtonElement>,
) {
  const IconComponent = icons[type];

  if (!IconComponent) return null;

  return (
    <span ref={forwardedRef}>
      <IconComponent
        className={cx(size ? sizes[size] : sizes['md'], className)}
        onClick={onClick}
      />
    </span>
  );
});

export default Icon;
